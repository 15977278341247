<template>
  <!-- 服务协议管理 -->
  <div class="containerbox serverContent">
    <div class="formcontent">
      <el-form ref="form" :model="formData" :rules="rules" label-width="114px">
        <div class="topSelect">
          <el-form-item
            class="tit_formItems"
            label="文章类型"
            prop="articleTypeId"
          >
            <el-select
              :popper-append-to-body="false"
              v-model="formData.articleTypeId"
              placeholder="请选择"
              clearable
              filterable
              :disabled="!isDetails"
            >
              <el-option
                v-for="(item, index) in articleTypeList"
                :label="item.articleTypeName"
                :value="item.articleTypeId + ''"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="tit_formItems" label="弹窗提示更新">
            <el-select
              :popper-append-to-body="false"
              v-model="formData.isPopUp"
              placeholder="请选择"
              clearable
              filterable
              :disabled="!isDetails"
            >
              <el-option
                v-for="(item, index) in isNoList"
                :label="item.label"
                :value="item.value"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="topSelect">
          <el-form-item
            class="tit_formItems"
            label="官网显示"
            prop="officialWebsiteShow"
          >
            <el-select
              :popper-append-to-body="false"
              v-model="formData.officialWebsiteShow"
              placeholder="请选择"
              clearable
              filterable
              :disabled="!isDetails"
            >
              <el-option
                v-for="(item, index) in isNoList"
                :label="item.label"
                :value="item.value"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="tit_formItems" label="APP显示" prop="appShow">
            <el-select
              :popper-append-to-body="false"
              v-model="formData.appShow"
              placeholder="请选择"
              clearable
              filterable
              :disabled="!isDetails"
            >
              <el-option
                v-for="(item, index) in isNoList"
                :label="item.label"
                :value="item.value"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div
          class="contentBox"
          v-for="(item, index) in formData.contents"
          :key="index"
        >
          <el-form-item class="tit_formItems" label="语言" prop="articleTypeId">
            <el-select
              :popper-append-to-body="false"
              v-model="item.languageCode"
              placeholder="请选择"
              clearable
              filterable
              disabled
            >
              <el-option
                v-for="(item, index) in languageCodeList"
                :label="item.name"
                :value="item.code"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章标题" prop="title">
            <el-input v-model="item.title"></el-input>
          </el-form-item>
          <el-form-item label="文章内容" prop="content">
            <div :id="'eidtContent_' + item.languageCode"></div>
          </el-form-item>
          <div class="addDelBtns" v-if="index > 0">
            <!-- <el-button
              v-if="isDetails"
              type="info"
              icon="el-icon-delete"
              @click="delLange(index, item.languageCode)"
              >删除语种</el-button> -->
            <el-popover
              v-if="isDetails"
              placement="top"
              width="300"
              v-model="isSHowDel"
            >
              <p>确定删除该语种吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  style="margin-right: 30px"
                  @click="isSHowDel = false"
                  >取消</el-button
                >
                <el-button
                  type="warning"
                  size="mini"
                  @click="delLange(index, item.languageCode)"
                  >确定</el-button
                >
              </div>
              <el-button type="info" icon="el-icon-delete" slot="reference"
                >删除语种</el-button
              >
            </el-popover>
          </div>
        </div>
        <div class="addDelBtns" v-if="isDetails">
          <el-popover placement="bottom" width="300" v-model="visible">
            <p>请选择新增的文章语种</p>
            <div>
              <el-select
                :popper-append-to-body="false"
                v-model="CurrentLanguage"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in languageCodeList"
                  :label="item.name"
                  :value="item.code"
                  :key="index"
                  :disabled="item.isable"
                >
                </el-option>
              </el-select>
            </div>
            <div
              style="
                display: flex;
                align-content: center;
                justify-content: space-around;
                margin: 20px auto;
              "
            >
              <el-button size="mini" @click="visible = false">取消</el-button>

              <el-button type="primary" size="mini" @click="conFirmLange"
                >确定</el-button
              >
            </div>
            <el-button slot="reference" icon="el-icon-circle-plus-outline"
              >新增语种</el-button
            >
          </el-popover>
        </div>

        <el-form-item style="text-align: center">
          <el-button @click="gobackPage()" style="margin-right: 50px"
            >关闭页面</el-button
          >
          <el-button v-if="isDetails" type="warning" @click="addServe"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
      <div style="height: 10px; width: 100%"></div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
// import paging from "@/components/pagings.vue";
import E from "wangeditor";

export default {
  inject: ["reload"],

  data() {
    return {
      editor_en: {},
      formData: {
        articleTypeId: "",
        appShow: 1,
        isPopUp: 0,
        officialWebsiteShow: 1,
        contents: [
          {
            content: "",
            languageCode: "zh-CN",
            title: "",
            editor: {},
          },
        ],
      },
      rules: {
        articleTypeId: [
          {
            required: true,
            message: "请选择",
            trigger: ["blur", "change"],
          },
        ],
        officialWebsiteShow: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        appShow: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        // title: [{ required: true, message: "", trigger: "blur" }],
        // content: [{ required: true, message: "", trigger: "blur" }],
        // cnContent: [{ required: true, message: "请输入", trigger: "blur" }],
        // enContent: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      pageNum: 1, //页码
      size: 50, //一页几条,
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      tableData: [], //表格数据
      tableHeigth: 0,
      selectMemberData: [],
      articleTypeList: [], //类型下拉列表
      isNoList: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      isDetails: true, //false==详情页面,不显示修改按钮
      languageCodeList: [
        {
          code: "zh-CN",
          name: "中文(简体)",
        },
        {
          code: "cht",
          name: "中文(繁体)",
        },
        {
          code: "en",
          name: "英文",
        },
        {
          code: "id",
          name: "印尼语",
        },
        {
          code: "uz",
          name: "乌兹别克语",
        },
        {
          code: "ru",
          name: "俄语",
        },
      ],
      langCodeList: ["zh-CN"],
      CurrentLanguage: "", //当前语言
      visible: false,
      isSHowDel: false,
    };
  },
  components: {
    // paging,
  },
  updated() {},
  created() {},
  mounted() {
    this.getTypeList();
  },
  activated() {
    let querys = this.$route.query;
    if (querys.id) {
      this.getDetail();
    }
    if (querys.pageType == "detail") {
      this.isDetails = false;
    } else {
      this.isDetails = true;
    }
  },
  watch: {},
  methods: {
    // 删除语种
    delLange(index, languageCode) {
      this.formData.contents.splice(index, 1);
      this.langCodeList.splice(this.langCodeList.indexOf(languageCode), 1);
      this.isSHowDel = false;
    },
    // 新增语种
    conFirmLange() {
      if (!this.CurrentLanguage) {
        this.$message.warning("请选择语种");
        return false;
      }
      if (this.langCodeList.indexOf(this.CurrentLanguage) !== -1) {
        this.$message.warning("该语言已存在");
        return false;
      }
      this.langCodeList.push(this.CurrentLanguage);
      let obj = {
        content: "",
        languageCode: this.CurrentLanguage,
        title: "",
        editor: {},
      };
      this.formData.contents.push(obj);
      this.visible = false;
      this.$nextTick(() => {
        this.initLangeuage(this.formData.contents.length - 1, obj.languageCode);
      });
      this.CurrentLanguage = "";
    },

    // 类型下拉
    getTypeList() {
      Api.articleTypeChooseList({
        articleCategory: "gong_lue:article_category",
      }).then((res) => {
        this.articleTypeList = res.data.result || [];
      });
    },
    //   获取详情内容
    getDetail() {
      let querydata = {};
      querydata.articleId = this.$route.query.id;
      Api.articleDetail(querydata).then((res) => {
        if (res.data.status == "success") {
          this.formData = res.data.result;
          this.formData.contents = res.data.result.conetent;

          this.initE();
          // this.formData.contents.forEach((item, index) => {
          //   this.initLangeuage(index, item.languageCode);
          // });
          this.$nextTick(() => {
            let that = this;
            setTimeout(() => {
              that.formData.contents.forEach((item) => {
                item.editor.txt.html(item.content);
              });
            }, 300);
          });
        }
      });
    },
    gobackPage() {
      // this.$router.go(-1);
      tools.closePage();
      this.reload();
    },
    //   初始化富文本编辑器
    initE() {
      this.$nextTick(() => {
        this.formData.contents.forEach((item, index) => {
          console.log("#eidtContent_" + item.languageCode);
          item.editor = new E("#eidtContent_" + item.languageCode);
          item.editor.config.height = 600; //富文本高度
          // 编辑器菜单栏配置
          // item.editor.config.menus = [
          //   "head", // 标题
          //   "bold", // 粗体
          //   "fontSize", // 字号
          //   "indent", //缩进
          //   "fontName", // 字体
          //   "italic", // 斜体
          //   "underline", // 下划线
          //   "strikeThrough", // 删除线
          //   "foreColor", // 文字颜色
          //   "backColor", // 背景颜色
          //   "link", // 插入链接
          //   "list", // 列表
          //   "justify", // 对齐方式
          //   "quote", // 引用
          //   "emoticon", // 表情
          //   "table", // 表格
          //   "code", // 插入代码
          //   "undo", // 撤销
          //   "redo", // 重复
          // ];
          // 自定义处理图片的上传逻辑
          item.editor.config.customUploadImg = (files, insert) => {
            const formData = new FormData();
            formData.append("file", files[0]);
            console.log("files", files);
            formData.append("fileName", files[0].name || ""); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            let signArr = [
              {
                key: "fileName",
                val: files[0].name || "",
              },
            ];

            let sign = this.Tools.getMD5Sign(signArr);
            formData.append("sign", sign);
            this.$showLoading();
            Api.uploadFile(formData)
              .then((res) => {
                this.$hideLoading();
                // 获取服务器返回的图片链接
                const imageUrl = res.data.result.url || "";
                let imgurl = imageUrl;
                let imgList = imageUrl.split("?") || [];
                if (imgList.length > 0) {
                  imgurl = imgList[0];
                }
                // 将图片链接插入到编辑器中
                insert(imgurl);
              })
              .catch(() => {
                this.$hideLoading();
              });

            // 发送图片文件到服务器
            // 可使用 fetch、axios、XMLHttpRequest 等方式发送请求

            // // 获取服务器返回的图片链接
            // const imageUrl =
            //   "https://img1.baidu.com/it/u=3663508195,2907650417&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500";

            // // 将图片链接插入到编辑器中
            // insert(imageUrl);
          };
          item.editor.create();
        });
      });
    },

    initLangeuage(index, languageCode) {
      this.formData.contents[index].editor = {};
      this.formData.contents[index].editor = new E(
        "#eidtContent_" + languageCode
      );
      this.formData.contents[index].editor.config.height = 500; //富文本高度
      // 编辑器菜单栏配置
      // this.formData.contents[index].editor.config.menus = [
      //   "head", // 标题
      //   "bold", // 粗体
      //   "fontSize", // 字号
      //   "indent", //缩进
      //   "fontName", // 字体
      //   "italic", // 斜体
      //   "underline", // 下划线
      //   "strikeThrough", // 删除线
      //   "foreColor", // 文字颜色
      //   "backColor", // 背景颜色
      //   "link", // 插入链接
      //   "list", // 列表
      //   "justify", // 对齐方式
      //   "quote", // 引用
      //   "emoticon", // 表情
      //   "table", // 表格
      //   "code", // 插入代码
      //   "undo", // 撤销
      //   "redo", // 重复
      // ];
      // 自定义处理图片的上传逻辑
      this.formData.contents[index].editor.config.customUploadImg = (
        files,
        insert
      ) => {
        const formData = new FormData();
        formData.append("file", files[0]);
        console.log("files", files);
        formData.append("fileName", files[0].name || ""); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
        let signArr = [
          {
            key: "fileName",
            val: files[0].name || "",
          },
        ];

        let sign = this.Tools.getMD5Sign(signArr);
        formData.append("sign", sign);
        this.$showLoading();
        Api.uploadFile(formData)
          .then((res) => {
            this.$hideLoading();
            // 获取服务器返回的图片链接
            const imageUrl = res.data.result.url || "";
            let imgurl = imageUrl;
            let imgList = imageUrl.split("?") || [];
            if (imgList.length > 0) {
              imgurl = imgList[0];
            }
            // 将图片链接插入到编辑器中
            insert(imgurl);
          })
          .catch(() => {
            this.$hideLoading();
          });

        // 发送图片文件到服务器
        // 可使用 fetch、axios、XMLHttpRequest 等方式发送请求

        // // 获取服务器返回的图片链接
        // const imageUrl =
        //   "https://img1.baidu.com/it/u=3663508195,2907650417&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500";

        // // 将图片链接插入到编辑器中
        // insert(imageUrl);
      };
      this.formData.contents[index].editor.create();
    },
    // 保存
    addServe() {
      //新增协议 保存接口
      let newcontents = [];
      this.formData.contents.forEach((item) => {
        let obj = {
          content: item.editor.txt.html(),
          contentId: item.contentId,
          languageCode: item.languageCode,
          title: item.title,
        };
        newcontents.push(obj);
      });
      let param = {
        appShow: this.formData.appShow,
        articleId: this.formData.articleId,
        articleTypeId: this.formData.articleTypeId,
        isPopUp: this.formData.isPopUp || 0,
        officialWebsiteShow: this.formData.officialWebsiteShow,
        contentV1Reqs: newcontents,
      };
      param.sign = tools.getSign(param);
      console.log(param);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          Api.updateArticle(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success("修改成功");
              this.gobackPage();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.formcontent {
  max-width: 1000px;
  margin: 0 auto;

  .topSelect {
    display: flex;
    align-items: center;
    .tit_formItems {
      width: 45%;
      margin-right: 2%;
    }

    /deep/ .el-select-dropdown {
      z-index: 10003 !important;
    }
  }

  .contentBox {
    border: 1px dashed #d7d7d7;
    padding: 20px;
    margin-bottom: 20px;
  }
}
.containerbox {
  // height: calc(100vh - 61px);
  height: 100vh;
  padding: 16px;
}

.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  margin-left: 12%;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.serverContent {
  // display: flex;
  // justify-content: center;

  .tableBox {
    background-color: #fff;
  }
  .btnList1 {
    width: auto;
    padding: 10px;
    border-radius: 4px;
  }
}

.addDelBtns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
